// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-js": () => import("/builds/markbeeson/markbeeson.net/src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-pages-404-js": () => import("/builds/markbeeson/markbeeson.net/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("/builds/markbeeson/markbeeson.net/src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-index-js": () => import("/builds/markbeeson/markbeeson.net/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/builds/markbeeson/markbeeson.net/.cache/data.json")

