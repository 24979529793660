module.exports = [{
      plugin: require('/builds/markbeeson/markbeeson.net/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-25068800-1","head":false},
    },{
      plugin: require('/builds/markbeeson/markbeeson.net/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"quality":80,"showCaptions":true},
    },{
      plugin: require('/builds/markbeeson/markbeeson.net/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
